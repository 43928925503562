import { Grid, Card, BackgroundImage, Badge, Stack, Title, Text, SimpleGrid } from '@mantine/core';

export function ServiceCards(): JSX.Element {
  const features = [
    {
      title: 'Phone Consultation',
      photo: '/assets/images/phone-consultation-1.jpeg',
      price: '€35',
    },
    {
      title: 'Request Prescription',
      photo: '/assets/images/prescription.webp',
      price: '€23',
    },
    {
      title: 'Video Consultation',
      photo: '/assets/images/video.jpg',
      price: '€35',
    },
    {
      title: 'Weight Loss',
      subtitle: 'Consultation',
      photo: '/assets/images/springScale.jpg',
      price: '€35',
    },
    {
      title: 'Travel Vaccination',
      photo: '/assets/images/travel-vaccinination.jpg',
      price: '€30',
    },
    {
      title: 'Sick Note',
      subtitle: 'Phone Consultation',
      photo: '/assets/images/doctor-writing.jpg',
      price: '€35',
    },
    {
      title: 'Fit to Return Letter',
      photo: '/assets/images/referral-letter.jpeg',
      price: '€35',
    },
    {
      title: 'Health Declaration',
      photo: '/assets/images/Nursing.jpg',
      price: '€23',
    },
  ];

  return (
    <SimpleGrid
      cols={{ base: 1, sm: 3, lg: 4 }}
      spacing={{ base: 10, sm: 'xl' }}
      verticalSpacing={{ base: 'md', sm: 'xl' }}
    >
      {features.map((card) => (
        <Card key={card.title} h={{ base: 150, sm: 300 }} shadow="lg" padding="lg" radius="md" withBorder>
          <Grid>
            <Grid.Col span={{ base: 4, sm: 12 }}>
              <Card.Section>
                <BackgroundImage src={card.photo} h={{ base: 150, sm: 200 }} ta={'right'}>
                  <Badge display={{ base: 'none', sm: 'inherit' }} color="green">
                    Only {card.price}
                  </Badge>
                </BackgroundImage>
              </Card.Section>
            </Grid.Col>
            <Grid.Col span={{ base: 8, sm: 12 }}>
              <Stack pt={16} align="center" justify="center">
                <Title order={4}>{card.title}</Title>
                <Text size="sm" c="dimmed">
                  {card.subtitle}
                </Text>
                <Badge display={{ base: 'block', sm: 'none' }} color="green">
                  Only {card.price}
                </Badge>
              </Stack>
            </Grid.Col>
          </Grid>
        </Card>
      ))}
    </SimpleGrid>
  );
}
