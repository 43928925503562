import { useParams } from "react-router-dom";
import { PlanDefinitionView } from '@onlinedoc/react';
import { Container, Loading } from "@iehr/react";
import { Box } from "@mantine/core";
import { GoogleReviews } from "../../components/GoogleReview";


export function PlanDefinitionViewPage(): JSX.Element | null {
    const { planDefinitionName } = useParams();
    if(!planDefinitionName  ){
        return <Loading />
    }
    return  <>
    <Box pb={64} bg="var(--mantine-color-body)">
      <Container p={0}>
        <PlanDefinitionView planDefinitionName={planDefinitionName}/>
      </Container>
    </Box>

    <Box>
      <Container p={0}>
        <GoogleReviews />
      </Container>
    </Box>
  </>

}