import { Navigate, Route, Routes } from 'react-router-dom';
import { RegisterPage } from './pages/RegisterPage';
import { SignInPage } from './pages/SignInPage';
import { LandingPage } from './pages/LandingPage';
import { AppShell } from '@mantine/core';
import { useHeadroom } from '@mantine/hooks';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { ConfirmQuestionnaireResponsePage } from './pages/lib/ConfirmQuestionnaireResponsePage';
import { PlanDefinitionsPage } from './pages/lib/PlanDefinitionsPage';
import { PlanDefinitionViewPage } from './pages/lib/PlanDefinitionViewPage';
import { StripePage } from './pages/lib/StripePage';
import { SchedulePage } from './pages/lib/SchedulePage';
import { AboutPage } from './pages/lib/AboutPage';
import { MarkdownPage } from './pages/lib/MarkdownPage';
import { ContactPage } from './pages/lib/ContactPage';
import { ScrollToTop } from '@onlinedoc/react';

export function App(): JSX.Element | null {
  const pinned = useHeadroom({ fixedAt: 1 });
  return (
    <AppShell bg="var(--mantine-color-appColor-0)">
      <Header />

      <AppShell.Main pt={16}>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/health/*" element={<PlanDefinitionsPage />}>
            <Route index element={<Navigate replace to="/health/Appointments" />} />
            <Route path=":category" element={<PlanDefinitionsPage />} />
          </Route>
          <Route path="/payment/*">
            <Route index element={<Navigate replace to="/health" />} />
            <Route path=":questionnaireResponseId" element={<StripePage />} />
          </Route>
          <Route path="/treatment/*">
            <Route path=":planDefinitionName" element={<PlanDefinitionViewPage />} />
          </Route>
          <Route path="/schedule/*">
            <Route index element={<Navigate replace to="/health" />} />
            <Route path=":planDefinitionName" element={<SchedulePage />} />
            <Route path=":planDefinitionName/:questionnaireResponseId" element={<SchedulePage />} />
            <Route
              path="confirmation/QuestionnaireResponse/:resourceId/*"
              element={<ConfirmQuestionnaireResponsePage />}
            />
          </Route>
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/terms" element={<MarkdownPage filename="terms.md" />} />
          <Route path="/privacy-policy" element={<MarkdownPage filename="privacy.md" />} />
          <Route path="/medicine-policy" element={<MarkdownPage filename="medicine.md" />} />
          <Route path="*" element={<Navigate replace to="/" />} />
          <Route path="signin" element={<SignInPage />} />
          <Route path="register" element={<RegisterPage />} />
        </Routes>
        </ScrollToTop>
      </AppShell.Main>

      <Footer />
    </AppShell>
  );
}
