import { useParams } from 'react-router-dom';
import { PlanDefinitions } from '@onlinedoc/react';
import { Container } from '@iehr/react';
import { Box } from '@mantine/core';
import { GoogleReviews } from '../../components/GoogleReview';

export function PlanDefinitionsPage(): JSX.Element | null {
  const { category } = useParams();
  return (
    <>
      <Box pt={64} pb={64} bg="var(--mantine-color-body)">
        <Container p={0}>
          <PlanDefinitions category={category!} />
        </Container>
      </Box>

      <Box>
        <Container p={0}>
          <GoogleReviews />
        </Container>
      </Box>
    </>
  );
}
