import { MantineColorShade, MantineProvider, createTheme } from '@mantine/core';
import { generateColors } from '@mantine/colors-generator';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import { IEHRClient } from '@iehr/core';
import { IEHRProvider } from '@iehr/react';
import '@iehr/react/styles.css';
import './styles.css';
import '@mantine/carousel/styles.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { APIProvider } from '@vis.gl/react-google-maps';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { App } from './App';
import { getConfig } from './config';
import { StripeProvider } from '@onlinedoc/react';

const config = getConfig();

const iehr = new IEHRClient({
  baseUrl: config.baseUrl,
  clientId: config.clientId,
  clientSecret: config.clientSecret,
  onUnauthenticated: () => (window.location.href = '/'),
  //onUnauthenticated: () => {
  //  if (window.location.pathname !== '/signin' && window.location.pathname !== '/oauth') {
  //    window.location.href = '/signin?next=' + encodeURIComponent(window.location.pathname + window.location.search);
  //  }
  //},
});

const theme = createTheme({
  colors: {
    appColor: generateColors(config.color),
  },
  primaryColor: 'appColor',
  primaryShade: config.shade as MantineColorShade,
  fontSizes: {
    xs: '0.6875rem',
    sm: '0.875rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.125rem',
  },
  components: {
    Container: {
      defaultProps: {
        size: 1200,
      },
    },
  },
});

const router = createBrowserRouter([{ path: '*', element: <App /> }]);

const navigate = (path: string): Promise<void> => router.navigate(path);

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);
root.render(
  <StrictMode>
    <IEHRProvider iehr={iehr} navigate={navigate}>
      <APIProvider apiKey={config.googleApiKey!} solutionChannel={''}>
        <StripeProvider publishableKey={config.stripePublishableKey!}>
          <MantineProvider theme={theme}>
            <ModalsProvider>
              <Notifications position="bottom-right" />
              <RouterProvider router={router} />
            </ModalsProvider>
          </MantineProvider>
        </StripeProvider>
      </APIProvider>
    </IEHRProvider>
  </StrictMode>
);
