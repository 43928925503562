import { Card, Text, ActionIcon, Group, SimpleGrid } from '@mantine/core';
import {
  IconCalendarClock,
  IconGenderFemme,
  IconGenderMale,
  IconWheelchair,
  IconFriends,
  IconHandStop,
  IconEye,
  IconVaccine,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

export function HealthButtons(): JSX.Element {
  const navigate = useNavigate();
  const services = [
    { icon: IconCalendarClock, label: 'Appointment', href: '/health/appointment' },
    //{ icon: <IconWifiOff />, label: 'Offline', href: '/health/offline'},
    { icon: IconGenderFemme, label: "Women's Health", href: '/health/female' },
    { icon: IconGenderMale, label: "Men's Health", href: '/health/male' },
    { icon: IconWheelchair, label: 'General Health', href: '/health/general,lung' },
    { icon: IconFriends, label: 'Sexual Health', href: '/health/sexual' },
    { icon: IconHandStop, label: 'Skin and Hair', href: '/health/skin,hair' },
    { icon: IconEye, label: 'Eye, Ear, Nose, Throat', href: '/health/eye,ear,nose,throat,mouth' },
    { icon: IconVaccine, label: 'Vaccination', href: '/health/vaccination' },
  ];

  return (
    <SimpleGrid
      cols={{ base: 1, xs: 2, sm: 3, md: 4 }}
      spacing={{ base: 10, sm: 'xl' }}
      verticalSpacing={{ base: 'md', sm: 'xl' }}
    >
      {services.map((card) => (
          <Card key={card.label} shadow="lg" padding="0" radius="md" withBorder ml={10} mr={10} onClick={() => navigate(card.href)}>
              <ActionIcon w={'100%'} variant="filled" aria-label="Settings">
                <card.icon size={16} style={{float:'left'}}/>
                <Text w={'calc( 100% - 32px)'} ta={'center'}>{card.label}</Text>    
              </ActionIcon>          
          </Card>
      ))}
    </SimpleGrid>
  );
}
