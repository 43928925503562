import { Document, Loading } from '@iehr/react';
import { useEffect, useState } from 'react';
import Markdown from 'react-markdown';

export interface MarkdownPageProps {
  readonly filename: string;
}

export function MarkdownPage({filename}: MarkdownPageProps): JSX.Element {
  const [md, setMd] = useState<string>();
  const [name, setName] = useState<string>();

  useEffect(() => {
    if (filename) {
      setName((preName)=>{
        if( preName!==filename){
          return filename;
        }
        return preName
      })
    }
  }, [filename]);

  useEffect(() => {
    if(name){
      fetch(`/assets/md/${name}`).then((r) => {
        r.text().then(setMd);
      });      
    }

  },[name])


  if (!md) {
    return <Loading />;
  }
  return (
    <Document>
      <Markdown>{md}</Markdown>
    </Document>
  );
}
