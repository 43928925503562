import { Avatar,  Group, Rating, ScrollArea, Stack, Text, Image, Card, Grid } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { Loading, useIEHR } from '@iehr/react';
import { useEffect, useState } from 'react';

type GoogleReview = {
  reviewId: string;
  profilePhotoUrl: string;
  displayName: string;
  rating: number;
  comment: string;
  createTime?: string;
};

interface GoogleReviews {
  profileUrl?: string;
  totalReviewCount: number;
  averageRating: number;
}

export function GoogleReviews(): JSX.Element {
  const iehr = useIEHR();
  const [reviews, setReviews] = useState<GoogleReview[]>();
  const [badge, setBadge] = useState<GoogleReviews>();

  useEffect(() => {
    if (!iehr) {
      return;
    }
    iehr.searchOne('Communication', { identifier: 'http://google.com/review/placeId|' }).then((comm) => {
      const parts = comm?.payload?.[0]?.contentString?.split('/').map((p) => Number(p)) ?? [5, 5];
      setBadge({
        totalReviewCount: parts[1],
        averageRating: parts[0],
      });
      iehr.searchResources('Communication', { identifier: 'http://google.com/review|', _sort: '-sent' }).then((comms) => {
        setReviews(comms.map((comm) => JSON.parse(comm.payload?.[0]?.contentString!) as GoogleReview));
      });
    });
    
  }, [iehr]);

  return (
    <>
      {!badge? <Loading /> : <Stack justify="center" align="center" gap="xl">
        <Stack>
          <Group justify="center" grow>
            <Stack justify="center" align="center" gap="0">
              <Text fz="lg" fw="bold" ta="center">
                EXCELLENT
              </Text>
              <Group justify="center">
                <Text fz="xl" fw="bold" c={'orange'}>
                  {badge.averageRating.toFixed(1)}
                </Text>
                <Rating value={badge.averageRating} readOnly />
              </Group>
              <Text fz="lg">Based on {badge.totalReviewCount} reviews</Text>
              <Group>
                <Text fz="lg" c={'dimmed'}>
                  {' '}
                  powered by{' '}
                </Text>
                <Image w={64} src="/assets/icons/google-logo.svg" />
              </Group>
            </Stack>
          </Group>
        </Stack>
      </Stack>}

      {!reviews? <Loading /> : <Carousel
        withIndicators
        slideSize={{ base: '100%', xs: '50%', sm: '33.3333%', md: '25%' }}
        loop
        slideGap="xl"
        p={48}
      >
        {reviews?.map((review) => (
          <Carousel.Slide key={review.reviewId}>
            <Card h={250} shadow="sm" radius="md" withBorder>
              <Stack>
                <Grid>
                  <Grid.Col span={'auto'}>
                    <Group gap={5}>
                      <Avatar p={0} m={0} size={'md'} src={review.profilePhotoUrl} />
                      <Stack gap="0">
                        <Text fz="md" fw="bold">
                          {review.displayName}
                        </Text>
                        <Text fz="lg" c="dimmed">
                          {getRelativeDate(new Date(review.createTime ?? ''))}
                        </Text>
                      </Stack>
                    </Group>
                  </Grid.Col>

                  <Grid.Col span={'content'}>
                    <Image w={20} h={20} src="/assets/icons/google-icon.svg" />
                  </Grid.Col>
                </Grid>
                <Rating value={review.rating} readOnly />
                <ScrollArea h={100} fz="lg" fw="500">
                  {review.comment}
                </ScrollArea>
              </Stack>
            </Card>
          </Carousel.Slide>
        ))}
      </Carousel>}
    </>
  );
}

export const getRelativeDate = (date: Date): string => {
  const today = new Date();
  const diffTime = Math.abs(today.getTime() - date.getTime());
  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
  const diffDays = Math.floor(diffHours / 24);
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffMonths / 12);

  if (diffYears > 0) {
    return `${diffYears} year${diffYears > 1 ? 's' : ''} ago`;
  } else if (diffMonths > 0) {
    return `${diffMonths} month${diffMonths > 1 ? 's' : ''} ago`;
  } else if (diffDays > 0) {
    return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
  } else {
    return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
  }
};
