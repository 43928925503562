import {
  Box,
  Burger,
  Button,
  Center,
  Collapse,
  Container,
  Divider,
  Drawer,
  Group,
  HoverCard,
  AppShell,
  ScrollArea,
  SimpleGrid,
  Text,
  ThemeIcon,
  UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconCalendarClock,
  IconCalendarPlus,
  IconChevronDown,
  IconEye,
  IconFriends,
  IconGenderFemme,
  IconGenderMale,
  IconHandStop,
  IconHeart,
  IconPhone,
  IconPrescription,
  IconVaccine,
  IconVideo,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import classes from './Header.module.css';
import { Logo, MenuLinkData } from '@onlinedoc/react';
import { useState } from 'react';

const serviceLinks: MenuLinkData[] = [
  { icon: IconCalendarClock, label: 'Appointment', href: '/health/appointment' },
  { icon: IconHeart, label: 'General Health', href: '/health/general,lung' },
  { icon: IconGenderFemme, label: "Women's Health", href: '/health/female' },
  { icon: IconGenderMale, label: "Men's Health", href: '/health/male' },
  { icon: IconFriends, label: 'Sexual Health', href: '/health/sexual' },
  { icon: IconHandStop, label: 'Skin and Hair', href: '/health/skin,hair' },
  { icon: IconEye, label: 'Eye, Ear, Nose, Throat', href: '/health/eye,ear,nose,throat,mouth' },
  { icon: IconVaccine, label: 'Vaccination', href: '/health/vaccination' },
];

const appointmentLinks: MenuLinkData[] = [
  { icon: IconPhone, label: 'Phone Consultation', href: '/treatment/PhoneConsultation' },
  { icon: IconVideo, label: 'Video Consultation', href: '/treatment/VideoConsultation' },
];

export function Header(): JSX.Element {
  const navigate = useNavigate();
  //const iehr = useIEHR();
  //const [planDefinitions, setPlanDefinitions] = useState<PlanDefinition[]>();
  //const [initialized, setInitialized] = useState<boolean>();

  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const [serviceLinksOpened, { toggle: toggleServiceLinks }] = useDisclosure(false);
  const [appointmentLinksOpened, { toggle: toggleAppointmentLinks }] = useDisclosure(false);
  /*
  Dynamic Menues
  useEffect(()=>{
    if(initialized){
      return;
    }
    setInitialized(true)
    
    iehr
    .searchResources(
      'PlanDefinition',
      `_count=100&_fields=topic,title,subtitle,relatedArtifact&_sort=title&status=active`
    )
    .then(setPlanDefinitions);
  })

  function planDefinitionLinks(categories:string[]) {
    const pds = planDefinitions?.filter(pd=>pd.topic?.some(t=>t.coding?.some(c=>categories.includes(c?.code!))))
    return pds?.map((item) => {
      const icon = item.relatedArtifact?.find((ra) => ra.label === 'icon')?.document?.url;
      const href = `/treatment/${item.name}`;

      return <UnstyledButton
        className={classes.subLink}
        key={item.title}
        onClick={() => {
          closeDrawer();
          navigate(href);
        }}
      >
        <Group wrap="nowrap" align="flex-start">
          <ThemeIcon size={34} variant="default" radius="md">
            <Image src={icon}/>
          </ThemeIcon>
          <div>
            <Text size="sm" fw={500}>
              {item.title}
            </Text>
            <Text size="xs" c="dimmed">
              {href}
            </Text>
          </div>
        </Group>
      </UnstyledButton>
    });
  }
    */

  function menuLinks(items: MenuLinkData[]) {
    return items.map((item) => (
      <UnstyledButton
        className={classes.subLink}
        key={item.label}
        onClick={() => {
          closeDrawer();
          navigate(item.href);
        }}
      >
        <Group wrap="nowrap" align="flex-start">
          <ThemeIcon size={34} variant="default" radius="md">
            <item.icon />
          </ThemeIcon>
          <div>
            <Text size="sm" fw={500}>
              {item.label}
            </Text>
            <Text size="xs" c="dimmed">
              {item.href}
            </Text>
          </div>
        </Group>
      </UnstyledButton>
    ));
  }

  return (
    <>
      <AppShell.Header px="md">
        <Container h="100%">
          <Group justify="space-between" h="100%">
            <UnstyledButton className={classes.logoButton} onClick={() => navigate('/')}>
              <Logo size={96} show="title" />
            </UnstyledButton>

            <Group style={{ height: '100%' }} gap={'sm'} className={classes.hiddenMobile}>
              <HoverCard width={300} position="bottom" radius="md" shadow="md" withinPortal>
                <HoverCard.Target>
                  <UnstyledButton onClick={() => navigate('/health')}>
                    <Center inline>
                      <IconCalendarPlus size={16} />
                      <Box component="span" ml={5}>
                        Appointment
                      </Box>
                      <IconChevronDown size={16} />
                    </Center>
                  </UnstyledButton>
                </HoverCard.Target>

                <HoverCard.Dropdown style={{ overflow: 'hidden' }}>
                  <SimpleGrid cols={1} spacing={0}>
                    {menuLinks(appointmentLinks)}
                  </SimpleGrid>
                </HoverCard.Dropdown>
              </HoverCard>

              <HoverCard width={600} position="bottom" radius="md" shadow="md" withinPortal>
                <HoverCard.Target>
                  <UnstyledButton onClick={() => navigate('/health')}>
                    <Center inline>
                      <IconPrescription size={16} />
                      <Box component="span" mr={5}>
                        Treatments
                      </Box>
                      <IconChevronDown size={16} />
                    </Center>
                  </UnstyledButton>
                </HoverCard.Target>

                <HoverCard.Dropdown style={{ overflow: 'hidden' }}>
                  <Group justify="space-between" px="md">
                    <Text fw={500}>Services</Text>
                    <UnstyledButton onClick={() => navigate('/health')}>Search...</UnstyledButton>
                  </Group>

                  <Divider my="sm" mx="-md" />

                  <SimpleGrid cols={2} spacing={0}>
                    {menuLinks(serviceLinks)}
                  </SimpleGrid>

                  <div className={classes.dropdownFooter}>
                    <Group justify="space-between">
                      <div>
                        <Text fw={500} size="sm">
                          I don't know.
                        </Text>
                        <Text size="xs" c="dimmed">
                          What should I do?
                        </Text>
                      </div>
                      <Group>
                        <Button variant="default" onClick={() => navigate('/health/appointment')}>
                          Consult with Doctor
                        </Button>
                        <Button variant="default" onClick={() => navigate('/health')}>
                          Search
                        </Button>
                      </Group>
                    </Group>
                  </div>
                </HoverCard.Dropdown>
              </HoverCard>
            </Group>

            <Group className={classes.hiddenMobile}>
              <Button variant="default" onClick={() => (window.location.href = 'https://app.onlinedoc.ie')}>
                Log in
              </Button>
              <Button onClick={() => (window.location.href = 'https://app.onlinedoc.ie/register')}>Sign up</Button>
            </Group>

            <Burger opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} />
          </Group>
        </Container>
      </AppShell.Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea style={{ height: 'calc(100vh - 60px)' }} mx="-md">
          <Divider my="sm" />

          <UnstyledButton onClick={() => navigate('/')}>Home</UnstyledButton>
          <UnstyledButton className={classes.link} onClick={toggleAppointmentLinks}>
            <IconCalendarPlus size={16} />
            <Box component="span" ml={5}>
              Appointment
            </Box>
            <IconChevronDown size={16} />
          </UnstyledButton>
          <Collapse in={appointmentLinksOpened}>{menuLinks(appointmentLinks)}</Collapse>

          <UnstyledButton className={classes.link} onClick={toggleServiceLinks}>
            <IconPrescription size={16} />
            <Box component="span" mr={5}>
              Treatments
            </Box>
            <IconChevronDown size={16} />
          </UnstyledButton>
          <Collapse in={serviceLinksOpened}>{menuLinks(serviceLinks)}</Collapse>

          <Divider my="sm" />

          <Group justify="center" grow pb="xl" px="md">
            <Button variant="default" onClick={() => (window.location.href = 'https://app.onlinedoc.ie')}>
              Log in
            </Button>
            <Button onClick={() => (window.location.href = 'https://app.onlinedoc.ie/register')}>Sign up</Button>
          </Group>
        </ScrollArea>
      </Drawer>
    </>
  );
}
