import { ActionIcon, Anchor, Box, Button, Group, Image, SimpleGrid, Stack, Text, UnstyledButton } from '@mantine/core';
import { Logo } from '@onlinedoc/react';
import { Icon, IconCertificate, IconCertificate2, IconMedicalCross, IconProps, IconSearch } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

interface MenuLink {
  icon: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<Icon>>;
  label: string;
  href: string;
}

export function Footer(): JSX.Element {
  const navigate = useNavigate();

  const contact: MenuLink[] = [
    { icon: IconSearch, label: 'Contact Us', href: '/contact-us' },
    { icon: IconCertificate, label: 'About Us', href: '/about-us' },
    //{ icon: IconCertificate2, label: 'Email', href: '/contact-us' },
    { icon: IconMedicalCross, label: 'Whatsapp', href: 'http://wa.me/353892716799' },
  ];
  const legal: MenuLink[] = [
    //{ icon: IconSearch, label: 'Search...', href: '/get-care' },
    { icon: IconCertificate, label: 'Privacy Policy', href: '/privacy-policy' },
    { icon: IconCertificate2, label: 'Terms & Conditions', href: '/terms' },
    { icon: IconMedicalCross, label: 'Medicine Policy Reference', href: '/medicine-policy' },
  ];

  function createLink(ml: MenuLink) {
    return (
      <Group key={ml.label}>
        <ml.icon size={16} color="white" />
        {ml.href.startsWith('http') && (
          <Anchor c={'white'} href={ml.href}>
            {ml.label}
          </Anchor>
        )}
        {!ml.href.startsWith('http') && (
          <UnstyledButton c={'white'} onClick={() => navigate(ml.href)}>
            {ml.label}
          </UnstyledButton>
        )}
      </Group>
    );
  }

  return (
    <Box p="xl" bg="#040747">
      <Stack justify="center" align="center" gap="xl">
        <SimpleGrid
          key={'links'}
          cols={{ base: 1, sm: 2, md: 4 }}
          spacing={{ base: 10, sm: 'xl' }}
          verticalSpacing={{ base: 'md', sm: 'xl' }}
        >
          <Stack gap={5}>
            <Text key={2} c="white">
              &nbsp;&nbsp; Mon-Fri: 9am-2pm, 3pm-9pm
            </Text>
            <Text key={3} c="white">
              &nbsp;&nbsp; Saturday: 9am-2pm, 3pm-9pm
            </Text>
            <Text key={4} c="white">
              &nbsp;&nbsp; Sunday: 9am-2pm, 3pm-9pm
            </Text>
          </Stack>

          <Stack></Stack>

          <Stack gap={5}>{contact.map(createLink)}</Stack>
          <Stack gap={5}>{legal.map(createLink)}</Stack>
        </SimpleGrid>
        <Stack justify="center" align="center" gap="xs">
          <Text c="white">Methods of Payment:</Text>
          <Image
            key={'payment'}
            w={300}
            src="/assets/images/payments.png"
            style={{ filter: 'grayscale(100%) invert(100%)' }}
          />
        </Stack>

        <Logo size={250} fillTitle="white" />
        <Text key={'copyright'} c="dimmed" size="sm">
          &copy; {new Date().getFullYear()} Made with ❤️ in Ireland
        </Text>
      </Stack>
    </Box>
  );
}
