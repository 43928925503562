import { normalizeErrorString } from '@iehr/core';
import { Questionnaire, QuestionnaireResponse } from '@iehr/fhirtypes';
import { Document, Loading, QuestionnaireForm, useIEHR, useResource } from '@iehr/react';
import { useCallback, useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { useParams, useNavigate } from 'react-router-dom';
import { IconCircleCheck, IconCircleOff } from '@tabler/icons-react';

export function ContactPage(): JSX.Element {
  const navigate = useNavigate();
  const iehr = useIEHR();
  const questionnaire: Questionnaire = {
    resourceType: "Questionnaire",
    url: "http://onlinedoc.ie/Questionnaire/contact",
    name: "Contact",
    title: "Contact",
    status: "active",
    item: [
      {
        linkId: "name",
        type: "string",
        required: true,
        text: "Name"
      },
      {
        linkId: "phone",
        type: "string",
        required: true,
        text: "Phone",
        extension: [
          {
            "url": "http://iehr.ie/fhir/ie/iehr/StructureDefinition/questionnaire-phone-item",
            "valueString": "{\"defaultCountry\": \"ie\",\"forceDialCode\": true}"
          }
        ]
      },
      {
        linkId: "email",
        type: "string",
        required: true,
        text: "Email",
        extension: [
          {
            "url": "http://iehr.ie/fhir/ie/iehr/StructureDefinition/questionnaire-email-item",
            "valueString": "email"
          }
        ]
      },
      {
        linkId: "message",
        type: "text",
        required: true,
        text: "Message"
      }
    ]
  }


  const handleOnSubmit = useCallback(
    (response: QuestionnaireResponse) => {
      if (!questionnaire) {
        return;
      }

      iehr
        .createResource<QuestionnaireResponse>(response)
        .then(() => {
          showNotification({
            icon: <IconCircleCheck />,
            title: 'Success',
            message: 'Answers recorded',
          });
          navigate('/thank-you');
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          showNotification({
            color: 'red',
            icon: <IconCircleOff />,
            title: 'Error',
            message: normalizeErrorString(err),
          });
        });
    },
    [iehr, navigate, questionnaire]
  );

  if (!questionnaire) {
    return <Loading />;
  }

  return (
    <Document width={800}>
      <QuestionnaireForm questionnaire={questionnaire} onSubmit={handleOnSubmit} />
    </Document>
  );
}
