import { useParams } from 'react-router-dom';
import { ScheduleView } from '@onlinedoc/react';
import { Document, Container, Loading } from '@iehr/react';
import { Box } from '@mantine/core';
import { GoogleReviews } from '../../components/GoogleReview';

export function SchedulePage(): JSX.Element | null {
  const { planDefinitionName, questionnaireResponseId } = useParams();
  if (!planDefinitionName) {
    return <Loading />;
  }
  return (
    <>
      <Box mih={'100vh'} pb={64} bg="var(--mantine-color-body)">
        <Container p={0}>
          <ScheduleView planDefinitionName={planDefinitionName} questionnaireResponseId={questionnaireResponseId!} />
        </Container>
      </Box>

      <Box>
        <Container p={0}>
          <GoogleReviews />
        </Container>
      </Box>
    </>
  );
}
