import { BackgroundImage, Box, Container, Stack, Text, Image } from '@mantine/core';
import { GoogleReviews } from '../components/GoogleReview';
import { ServiceCards } from '../components/ServiceCards';
import { HealthButtons } from '../components/HealthButtons';
import { useEffect } from 'react';

export function LandingPage(): JSX.Element {

  return (
    <Stack gap={64}>
      <BackgroundImage w={'100vw'} h={'100vh'} src="/assets/images/phone-consultation.jpeg">
        <Container pt={200} px={0} size="30rem">
          <Stack justify="center" align="center" gap="xl">
            <Stack justify="center" align="center" gap={0}>
            <Text c={'white'} fz={25} fw="bold" style={{ textShadow: '0px 0px 8px #000000' }}>
              Consult with a Doctor
            </Text>
            <Text c={'white'} fz={25} fw="bold" style={{ textShadow: '0px 0px 8px #000000' }}>
              Anytime, Anywhere
            </Text>              
            </Stack>

            <Text size="xl" mt="md" c={'white'} fw="bold" style={{ textShadow: '0px 0px 8px #000000' }}>
              Request Treatment…………€23
              <br />
              Phone Consultation………..€35
              <br />
              Video Consultation……...…€39
              <br />
            </Text>

            <Image w={350} src="/assets/images/payments.png" bg="rgba(255, 255, 255, .5)" radius={10} />
          </Stack>
        </Container>
      </BackgroundImage>

      <Box>
        <Container p={0}>
          <GoogleReviews />
        </Container>
      </Box>

      <Box pt={64} pb={64} bg="var(--mantine-color-body)">
        <Container>
          <ServiceCards />
        </Container>
      </Box>

      <Box>
        <Container p={0}>
          <HealthButtons />
        </Container>
      </Box>
    </Stack>
  );
}
