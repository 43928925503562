import { Stack, Image, Title, Group, Text, Box, Container, UnstyledButton, SimpleGrid } from '@mantine/core';
import { GoogleReviews } from '../../components/GoogleReview';

export function AboutPage(): JSX.Element {
  return (
    <Stack gap="xl">
      <Box bg="var(--mantine-color-body)">
        <Container p={'xl'}>
          <Stack  align="center" justify="center" gap="xl">
            <Title order={4} c="red">
              Introducing OnlineDoc : Revolutionising Virtual Healthcare in Ireland
            </Title>
            <Image src="/assets/images/office-about-1.jpg"></Image>
            <Title order={4} c="red">
              Pioneering Automated Virtual Clinic and Online GP Service in Ireland
            </Title>
            <SimpleGrid h={300} cols={{ base: 1, xs: 2 }}>
              <Stack align="center" justify="center" gap="xl">
                <Text>
                  Our mission is to tackle some of the world’s most meaningful issues in healthcare, starting with timely
                  and convenient access to doctors and other healthcare providers – so that you can feel better, faster.
                </Text>
                <Text>
                  We believe our technology and new models of healthcare delivery will accelerate the world’s journey
                  towards a more sustainable healthcare system, and collaboration on patient care at a remote,
                  understaffed areas.
                </Text>
                <Text>
                  We’re transforming patient care, creating new models of care and opportunities for patients,
                  organizations, and businesses.
                </Text>
              </Stack>
              <Image h={'100%'} fit="contain" src="/assets/images/office-about-2.jpg"></Image>
            </SimpleGrid>
          </Stack>
        </Container>        
      </Box>

      <Box>
        <Container p={0}>
          <GoogleReviews />
        </Container>
      </Box>

      <Box bg="var(--mantine-color-body)">
        <Container p={'xl'}>
          <Stack align="center" justify="center" gap="xl">
            <SimpleGrid h={300} cols={{ base: 1, xs: 2 }}>
              <Image h={'100%'} fit="contain" src="/assets/images/office-about-3.jpg"></Image>
              <Stack align="center" justify="center" gap="xl">
                <Text>
                  Our team consists of experienced and knowledgeable General Practitioners and IT professionals. When you
                  submit a request, our system will quickly select an available member based on their availability,
                  allowing us to operate efficiently through our cloud infrastructure across Ireland.
                </Text>
                <Text>
                  We offer a range of benefits, including convenience, accessibility, and flexibility. You can consult
                  with a doctor from the comfort of your own home or any location with an internet connection. Our
                  services are particularly useful for non-emergency medical consultations, prescription renewals, and
                  general medical advice.
                </Text>
                <UnstyledButton></UnstyledButton>
              </Stack>
            </SimpleGrid>
          </Stack>
        </Container>        
      </Box>

    </Stack>
  );
}
